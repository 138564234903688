import {Injectable} from '@angular/core';

import {ApiService} from '../api/api.service';
import {User} from '../../classes/user.class';
import {Observable} from 'rxjs';

@Injectable()
export class UserService {

    constructor(private apiService: ApiService) {
    }

    register(data): Observable<any> {
        return this.apiService.postCall$('register', data);
    }

    saveUser(user: User) {
        return this.apiService.postCall$<User>('users', user);
    }

    savePrivacy(preferences: { preference, studentId }) {
        return this.apiService.postCall$<User>('users/privacy', {preferences});
    }

    saveProfile(user: User) {
        return this.apiService.postCall$<User>('users/profile', user);
    }

    saveProfileImage(profile_image: string) {
        return this.apiService.postCall$<User>('users/profileImage', {profile_image});
    }

    getUser(userId: number) {
        return this.apiService.getCall$<User>(`users/${userId}`);
    }

    getParnassysUser(userId: number) {
        return this.apiService.getCall$<User>(`users/parnassys/${userId}`);
    }

    getProfile() {
        return this.apiService.getCall$<User>(`users/profile`);
    }

    getUsers() {
        return this.apiService.getCall$<User[]>('users');
    }

    getUsersForSchools(schoolIds) {
        return this.apiService.getCall$<User[]>('users/users-for-schools', {schoolIds});
    }

    getParnassysUsers(onlyselectedschool: boolean, clean?: boolean) {
        return this.apiService.getCall$<User[]>('users/parnassys', {onlyselectedschool, clean});
    }

    getCount() {
        return this.apiService.getCall$<{ usercount_selected: number, usercount_all: number }>('users/count');
    }

    deleteUser(userId: number) {
        return this.apiService.deleteCall$(`users/${userId}`);
    }

}
