<div class="dialog-container">
    <h2 class="condensed dialog-title" mat-dialog-title>Terugbetalen</h2>
    <div class="dialog-content" mat-dialog-content>
        <div>
            @if (data.subscription) {
                Betaal (een deel) van het bedrag terug aan {{data.subscription.student.name}}.<br>
                Er kan nooit meer terugbetaald worden dan het totaal betaalde bedrag.
            } @else {
                Betaal (een deel) van het bedrag terug. <br>Er wordt nooit meer terugbetaald worden dan het totale bedrag.
            }
        </div>
        <mat-form-field class="w-100 mt-2">
            <mat-label>Terug te betalen bedrag</mat-label>
            <input [formControl]="fcAmount"
                   appDecimalInput
                   [allowNegative]="false"
                   decimalInput="2" matInput type="text">
            <mat-error [control]="fcAmount"></mat-error>
        </mat-form-field>
    </div>
    <div class="d-flex flex-wrap justify-content-between" mat-dialog-actions>
        <button mat-dialog-close class="mr-3" mat-button>Annuleren</button>
        <button (click)="refund()"
                cdkFocusInitial
                color="primary"
                mat-flat-button>
            Terugbetalen <i *ngIf="refunding" class="fas fa-circle-notch fa-spin"></i>
        </button>
    </div>
</div>
