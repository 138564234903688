import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Event} from '../classes/event.class';
import {EventSubscription} from '../classes/event-subscription';

@Injectable({
    providedIn: 'root'
})
export class AfterSchoolService {

    constructor(private apiService: ApiService) {
    }

    getList(
        limit?: number,
        skip?: number,
        search = ''
    ) {
        return this.apiService.getCall$<Event[]>(`after-school/${limit}/${skip}`, {
            search
        });
    }

    subscribe(subscription: any) {
        return this.apiService.postCall$<EventSubscription[]>('after-school/subscribe', subscription);
    }

    pay(event_id: number, price: number) {
        return this.apiService.postCall$('after-school/pay', {
            event_id
        });
    }

    presence(presenceList) {
        return this.apiService.postCall$('after-school/presence', {
            presenceList
        });
    }

    refund(subscription_id: number, amount?: number) {
        return this.apiService.postCall$<EventSubscription>('after-school/refund', {
            subscription_id,
            amount
        });
    }

    unsubscribe(subscription_id: number) {
        return this.apiService.postCall$<EventSubscription>('after-school/unsubscribe', {
            subscription_id
        })
    }
}
