import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {combineLatest, of} from 'rxjs';
import {EventService} from '../../services/event.service';
import {Event} from '../../classes/event.class';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {Classroom} from '../../classes/classroom.class';
import {LocalStorage} from '../../storage.class';
import {debounceTime, startWith} from 'rxjs/operators';
import {DateNLFormatAdapter} from '../../date-adapters/date-nlformat-adapter';
import {DateAdapter} from '@angular/material/core';
import {formatDate, Location} from '@angular/common';
import {Image} from '../../classes/image.class';
import {Routenames} from '../../route-names.enum';
import {Utils} from '../../utils.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {Daterange} from '../../classes/daterange.class';
import {validTime} from '../../validators/valid-time.validator';
import {handleTimeValueChange} from '../../utils/handle-time-value-change';
import {RequiredRule} from './event-edit.required-rules';
import {CordovaService} from '../../services/cordova.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Document} from '../../classes/document.class';
import {SchoolService} from '../../services/school/school.service';
import {School} from '../../classes/school.class';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';

@Component({
    selector: 'app-event-edit',
    templateUrl: './event-edit.component.html',
    styleUrls: ['./event-edit.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: DateNLFormatAdapter}
    ]
})
export class EventEditComponent extends CodaltComponent implements OnInit {

    masterSchool = false;
    reserve = false;
    saving = false;
    form: UntypedFormGroup;
    classrooms: Classroom[];
    classroomGroups: string[] = [];
    event: Event;
    faDateranges: UntypedFormArray;

    schools: School[];

    allUsers: User[];
    users: User[];

    constructor(private eventService: EventService,
                private classroomService: ClassroomService,
                private schoolService: SchoolService,
                private userService: UserService,
                private location: Location,
                private router: Router,
                private dialog: ConfirmDialogService,
                private cordovaService: CordovaService,
                private route: ActivatedRoute,
                private cdref: ChangeDetectorRef) {
        super();
    }

    reorderImages(event: CdkDragDrop<Image[]>) {
        const movedImage = this.event.images[event.previousIndex];
        this.event.images.splice(event.previousIndex, 1);
        this.event.images.splice(event.currentIndex, 0, movedImage);
        this.eventService.sortImages(this.event).subscribe(() => {
        });
    }

    reorderDocuments(event: CdkDragDrop<Document[]>) {
        const movedImage = this.event.documents[event.previousIndex];
        this.event.documents.splice(event.previousIndex, 1);
        this.event.documents.splice(event.currentIndex, 0, movedImage);
        this.eventService.sortDocuments(this.event).subscribe(() => {
        });
    }

    openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.form.dirty) {
                this.dialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen opslaan of verwerpen?`,
                    'Wijzigingen opslaan',
                    'Wijzigingen verwerpen').then(() => {
                    this.save();
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    createDaterangeForm(daterange: Daterange) {
        let fcStarttime: UntypedFormControl, fcEndtime: UntypedFormControl, fcStartdate: UntypedFormControl,
            fcEnddate: UntypedFormControl, fcAllday: UntypedFormControl, fcDateRangeId: UntypedFormControl;
        const formGroup = new UntypedFormGroup({
            daterange_id: fcDateRangeId = new UntypedFormControl(daterange?.id),
            start_date: fcStartdate = new UntypedFormControl(daterange.start_date, Validators.required),
            end_date: fcEnddate = new UntypedFormControl(daterange.end_date, Validators.required),
            all_day: fcAllday = new UntypedFormControl(daterange.all_day)
        });
        formGroup.addControl('start_time', fcStarttime = new UntypedFormControl(
            daterange.start_date ? formatDate(daterange.start_date, 'H:mm', 'nl') : null,
            {validators: [validTime(), RequiredRule(formGroup)], updateOn: 'blur'}
        ));
        formGroup.addControl('end_time', fcEndtime = new UntypedFormControl(
            daterange.end_date ? formatDate(daterange.end_date, 'H:mm', 'nl') : null,
            {validators: [validTime(), RequiredRule(formGroup)], updateOn: 'blur'}
        ));

        this.subscriptions.add(fcStarttime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcStarttime)
        ));
        this.subscriptions.add(fcEndtime.valueChanges.subscribe(
            value => handleTimeValueChange(value, fcEndtime)
        ));
        this.subscriptions.add(fcStartdate.valueChanges.subscribe(() => {
            if (!fcEnddate.value && !fcAllday.value) {
                fcEnddate.setValue(fcStartdate.value);
            }
        }));

        this.subscriptions.add(formGroup.valueChanges.subscribe(() => {
            [{date: fcStartdate, time: fcStarttime}, {date: fcEnddate, time: fcEndtime}].forEach(group => {
                if (group.time.value && group.date.value) {
                    const time = group.time.value.split(':');
                    if (time.length === 2) {
                        const date = new Date(group.date.value);
                        date.setHours(time[0]);
                        date.setMinutes(time[1]);
                        if (date.toString() !== (new Date(group.date.value)).toString()) {
                            group.date.setValue(date);
                        }
                    }
                }
                if (!group.time.value && fcAllday.value) {
                    group.time.setValue('0:00');
                }
            });
        }));

        return formGroup;
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school && !this.event) {
                this.masterSchool = ['offer', 'master'].includes(LocalStorage.school.type);
                this.getEvent(params['id']);
            }
        }));
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            if (['PUBLIC', 'EMPLOYEE'].indexOf(this.form.get('access_level').value) !== -1) {
                this.form.get('classrooms')?.setValue([]);
            }
            this.saving = true;
            this.eventService.save(this.form.getRawValue()).subscribe(event => {
                Object.assign(this.event, event.data);
                this.saving = false;
                this.form.markAsPristine();
                this.router.navigateByUrl(Routenames.events);
            }, error => {
                this.dialog.confirm(
                    'Fout bij het opslaan',
                    `Reden: ${error.message}`,
                    'Sluiten',
                    null)
                    .then(() => {

                    }, () => {

                    });
                this.saving = false;
            });
        }
    }

    uploadedFiles(files) {
        if (!this.event.images) {
            this.event.images = [];
        }
        files.file.forEach(file => {
            this.eventService.addImage(this.event.id, file.filename).subscribe(img => {
                const loading = this.event.images.find(p => !p.id);
                if (loading) {
                    img.data.thumb = loading.thumb;
                    Object.assign(loading, img.data);
                } else {
                    this.event.images.push(img.data);
                }
            });
        });
    }

    uploadFiles(files?: File[]) {
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!this.event.images) {
                    this.event.images = [];
                }
                this.event.images.push({
                    thumb: reader.result.toString()
                } as Image);
            };
        });
    }

    deleteImage(image: Image) {
        this.eventService.rmImage(image.id).subscribe(() => {
            const index = this.event.images.indexOf(image);
            this.event.images.splice(index, 1);
        });
    }

    deleteDocument(document: Document) {
        this.eventService.rmDocument(document.id).subscribe(() => {
            const index = this.event.documents.indexOf(document);
            this.event.documents.splice(index, 1);
        });
    }

    uploadedMainImage(file) {
        this.form.get('image').setValue(file ? file.file.filename : null);
    }

    uploadMainImage(files) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.event.image_thumb = reader.result.toString();
        };
        this.form.get('image').setValue(null);
    }

    uploadedDocuments(files) {
        if (!this.event.documents) {
            this.event.documents = [];
        }
        files.file.forEach(file => {
            this.eventService.addDocument(this.event.id, file.filename).subscribe(img => {
                const loading = this.event.documents.find(p => !p.id);
                if (loading) {
                    img.data.thumb = loading.thumb;
                    Object.assign(loading, img.data);
                } else {
                    this.event.documents.push(img.data);
                }
            });
        });
    }

    addDaterange() {
        const daterange = new Daterange();
        this.event.dateranges.push(daterange);
        this.faDateranges.push(this.createDaterangeForm(daterange));
    }

    removeRange(index: number) {
        this.faDateranges.removeAt(index);
    }

    private getEvent(slug) {
        this.reserve = slug === 'nieuw';
        const schools$ = this.masterSchool ? this.schoolService.allSchools() : of({data: []});
        const classrooms$ = this.masterSchool ? of({data: []}) : this.classroomService.getClassrooms();
        const event$ = this.reserve ? this.eventService.reserve() : this.eventService.get(slug, this.route.snapshot.url[1].path === 'kopie');
        combineLatest([classrooms$, event$, schools$])
            .subscribe(([classrooms, event, schools]) => {
                this.classrooms = classrooms.data;
                if (event.data.classrooms.filter(c => !!c.deleted_at)?.length) {
                    this.classrooms.push(...event.data.classrooms.filter(c => !!c.deleted_at));
                }
                if (this.classrooms.length > 3) {
                    this.classroomGroups = [...new Set(this.classrooms.filter(p => !!p.groupment).map(p => p.groupment))];
                }
                if (!AuthorisationService.hasFeature('publicEvents') && this.classrooms.length < 1 && !this.masterSchool) {
                    this.dialog.confirm(
                        'Je kunt geen kalender item aanmaken',
                        `Omdat je in parnassys niet bent gekoppeld aan klassen
                    en je geen rechten hebt om publieke kalender items te maken`,
                        'Terug naar overzicht',
                        null).then(() => {
                        this.router.navigateByUrl(Routenames.articles);
                    });
                }
                this.event = event.data;
                LocalStorage.school = LocalStorage.schools.find(p => +p.id === +this.event.school_id);
                this.generateForm(this.event);
                if (!this.reserve) {
                    this.location.replaceState(`${Routenames.events}/${event.data.slug}/bewerken`);
                } else {
                    this.cordovaService.setBackbuttonAction(() => {
                        this.router.navigateByUrl(Routenames.events);
                    });
                }

                this.schools = schools.data.filter(s => s.type === 'subsite');
                this.cdref.detectChanges();
            }, error => {
                this.dialog.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                    this.router.navigateByUrl(Routenames.events);
                });
            });
    }

    private generateForm(event: Event) {
        let classrooms: (string | number)[] = event.classrooms ? event.classrooms.map(c => c.id) : [];
        let images = event.images ? event.images.map(img => img.id) : [];

        const directClassroomsForThisArticle = (LocalStorage.user.classrooms ?? []).filter(c => this.classrooms.map(d => d.id).indexOf(c.id) > -1).map(c => c.id);

        const permittedToAllClassrooms = directClassroomsForThisArticle.length === this.classrooms.length;

        if (!this.reserve || (AuthorisationService.hasFeature('publicArticles') && directClassroomsForThisArticle.length === 0)) {
            classrooms = classrooms.length === 0 ? [null] : classrooms;
        } else if (directClassroomsForThisArticle.length > 0 && !permittedToAllClassrooms) {
            classrooms = classrooms.length === 0 ? [...directClassroomsForThisArticle] : classrooms;
        } else {
            classrooms = classrooms.length === 0 && this.classrooms.length === 1 ? [this.classrooms[0].id] : classrooms;
        }
        classrooms = this.appendWithGroupments(classrooms);

        const fcClassrooms = new UntypedFormControl(classrooms, Validators.required);
        const fcAccessLevel = new UntypedFormControl(event.access_level ? event.access_level : 'LOGGEDIN');
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(event.id),
            title: new UntypedFormControl(event.title, Validators.required),
            alert: new UntypedFormControl(event.alert || false),
            content: new UntypedFormControl(event.content),
            images: new UntypedFormControl(images),
            access_level: fcAccessLevel,
            image: new UntypedFormControl(event.image),
            dateranges: this.faDateranges = new UntypedFormArray([], Validators.required),
            schools: new UntypedFormControl(event.schools?.map(s => s.id) ?? []),
            schoolWide: new UntypedFormControl(!event?.classrooms?.length && AuthorisationService.hasFeature('publicArticles'))
        });
        if (event.classrooms.filter(c => !!c.deleted_at)?.length) {
            this.form.get('schoolWide').disable();
        }
        if (LocalStorage.school.type === 'offer') {
            this.form.addControl('organizer_email', new UntypedFormControl(event?.organizer?.email, Validators.email));
            this.form.addControl('price', new UntypedFormControl(event?.price, Validators.required));
            this.form.addControl('register_before', new UntypedFormControl(event?.register_before, Validators.required));
            this.form.addControl('max_subscriptions', new UntypedFormControl(event?.max_subscriptions));
            this.form.addControl('subscribe_allowed', new UntypedFormControl(event?.subscribe_allowed?.map(s => s.user_id) ?? []));

            this.subscriptions.add(this.form.get('schools').valueChanges.pipe(startWith(event.schools?.map(s => s.id)), debounceTime(500)).subscribe(schools => {
                if(schools) {
                    this.subscriptions.add(this.userService.getUsersForSchools(schools).subscribe(users => {
                        this.users = users.data;
                    }));
                }
            }));
        }
        if (!this.masterSchool) {
            if (this.reserve) {
                if (LocalStorage.showClassroomsEvents?.length) {
                    if (AuthorisationService.hasFeature('AllclassroomsList')) {
                        fcClassrooms.setValue(LocalStorage.showClassroomsEvents);
                    } else {
                        const ownClassrooms = LocalStorage.user?.classrooms?.map(c => c.id);
                        fcClassrooms.setValue(LocalStorage.showClassroomsEvents.filter(c => ownClassrooms.includes(c)));
                    }
                }
                if (!LocalStorage.showSchoolWideEvents && !LocalStorage.showAllClassroomsEvents && !LocalStorage.showClassroomsEvents?.length) {
                    fcClassrooms.setValue(LocalStorage.user?.classrooms?.map(c => c.id));
                }
                if (LocalStorage.showAllClassroomsEvents) {
                    fcClassrooms.setValue(this.classrooms.map(c => c.id));
                }
            }
            if (fcClassrooms.value?.filter(v => v !== null)?.length) {
                this.form.get('schoolWide').setValue(false);
            }
            this.form.addControl('classrooms', fcClassrooms);
        }

        this.subscriptions.add(this.form.get('schoolWide').valueChanges.subscribe(schoolWide => {
            if (schoolWide) {
                this.form.get('classrooms')?.setValue([null]);
            }
        }));

        this.subscriptions.add(fcClassrooms.valueChanges.subscribe(newClassrooms => {
            const selectedNew = JSON.stringify(newClassrooms);
            const prevClassrooms = this.form.value.classrooms;
            if (prevClassrooms.indexOf(null) === -1 && newClassrooms.indexOf(null) !== -1 && newClassrooms.length > 1) {
                newClassrooms = [];
                fcClassrooms.setValue(newClassrooms);
            }
            if (newClassrooms.length > 1 && newClassrooms.indexOf(null) !== -1) {
                newClassrooms = newClassrooms.filter(a => a !== null);
            }

            // GROUPMENT => Handle new groups
            newClassrooms.forEach(nc => {
                if (('' + nc).substr(0, 6) === 'group_') {
                    if (prevClassrooms.indexOf(nc) === -1) {
                        newClassrooms.push(
                            ...this.classrooms
                                   .filter(c => newClassrooms.indexOf(c.id) === -1)
                                   .filter(c => c.groupment === nc.substr(6)).map(c => c.id)
                        );
                    }
                }
            });

            // GROUPMENT => Handle removed groups
            prevClassrooms.forEach(pc => {
                if (('' + pc).substr(0, 6) === 'group_' && newClassrooms.indexOf(pc) === -1) {
                    const toBeRemovedClassrooms = this.classrooms.filter(c => c.groupment === pc.substr(6)).map(c => c.id);
                    const canBeRemovedClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) !== -1);
                    if (toBeRemovedClassrooms.length === canBeRemovedClassrooms.length) {
                        newClassrooms = newClassrooms.filter(nc => toBeRemovedClassrooms.indexOf(nc) === -1);
                    }
                }
            });

            // GROUPMENT => Check whether full group is checked
            newClassrooms = this.appendWithGroupments(newClassrooms);

            if (selectedNew !== JSON.stringify(newClassrooms)) {
                fcClassrooms.setValue(newClassrooms);
            }
            if (newClassrooms.find(nc => !!nc) && fcAccessLevel.value === 'PUBLIC') {
                fcAccessLevel.setValue('LOGGEDIN');
            }
        }));
        this.subscriptions.add(fcAccessLevel.valueChanges.subscribe(newAccessLevel => {
            if (['PUBLIC', 'EMPLOYEE'].indexOf(newAccessLevel) !== -1 && fcClassrooms.value[0] !== null) {
                fcClassrooms.setValue([null]);
            }
            const oldAccessLevel = this.form.value.access_level;
            if (['PUBLIC', 'EMPLOYEE'].indexOf(oldAccessLevel) !== -1 && ['PUBLIC', 'EMPLOYEE'].indexOf(newAccessLevel) === -1 && fcClassrooms.value[0] === null) {
                fcClassrooms.setValue([]);
            }
        }));
        if (this.event.dateranges) {
            this.event.dateranges.forEach(daterange => {
                this.faDateranges.push(this.createDaterangeForm(daterange));
            });
        } else {
            this.event.dateranges = [];
            this.addDaterange();
        }
    }


    private appendWithGroupments(classrooms: (string | number)[]) {
        this.classroomGroups.forEach(clg => {
            const allGroupedClassrooms: (string | number)[] = this.classrooms.filter(c => c.groupment === clg).map(c => c.id);
            const selectedGroupedClassrooms = classrooms.filter(nc => allGroupedClassrooms.indexOf(nc) !== -1);
            if (allGroupedClassrooms.length === selectedGroupedClassrooms.length) {
                if (classrooms.filter(p => ('' + p).substr(6) === clg).length === 0) {
                    classrooms.push('group_' + clg);
                }
            } else {
                classrooms = classrooms.filter(a => ('' + a).substr(6) !== clg);
            }
        });
        return classrooms;
    }
}
