import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Event} from '../../classes/event.class';
import {EventSubscription} from '../../classes/event-subscription';
import {FormControl, Validators} from '@angular/forms';
import {AfterSchoolService} from '../../services/after-school.service';
import {tap} from 'rxjs/operators';
import {concat} from 'rxjs';
import {CodaltComponent} from '../../codalt.component';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-event-partly-refund',
    templateUrl: './event-partly-refund.component.html',
    styleUrl: './event-partly-refund.component.scss'
})
export class EventPartlyRefundComponent extends CodaltComponent implements OnInit {

    refunding = false;

    fcAmount: FormControl;

    constructor(
        public dialogRef: MatDialogRef<EventPartlyRefundComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            event: Event,
            subscription: EventSubscription
        },
        private afterSchoolService: AfterSchoolService) {
        super();
    }

    ngOnInit() {
        this.fcAmount = new FormControl(
            Math.min(this.data.event.price, this.data.event.price - (this.data?.subscription?.total_refund ?? 0)),
            [Validators.required, Validators.max(this.data.event.price - (this.data?.subscription?.total_refund ?? 0)), Validators.min(0.05)]
        );
    }


    refund() {
        Utils.triggerValidation(this.fcAmount);
        if (this.fcAmount.valid) {
            this.refunding = true;
            if (this.data.subscription) {
                this.afterSchoolService.refund(this.data.subscription.id, this.fcAmount.value).subscribe(eventSubscription => {
                    this.data.subscription.total_refund = eventSubscription.data.total_refund;
                    this.refunding = false;
                    this.dialogRef.close();
                });
            } else {
                const progress = [];
                const calls$ = [];
                this.data.event.all_subscriptions.forEach(subs => {
                    calls$.push(this.afterSchoolService.refund(subs.id, this.fcAmount.value).pipe(tap(eventSubscription => {
                        subs.total_refund = eventSubscription.data.total_refund;
                        progress.push(eventSubscription.data);
                    })));
                });
                this.subscriptions.add(concat(...calls$).subscribe(result => {
                    if (progress.length === this.data.event.all_subscriptions.length) {
                        this.refunding = false;
                        this.dialogRef.close();
                    }
                }, error => {
                    console.error(error);
                    this.refunding = false;
                }));
            }
        }
    }
}
