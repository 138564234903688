import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {DetailComponent} from './detail/detail.component';
import {ChatComponent} from './chat/chat.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiService} from './services/api/api.service';
import {AuthenticationService} from './services/auth/authentication.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PagesComponent} from './pages/pages.component';
import {PageEditComponent} from './page-edit/page-edit.component';
import {PageHistoryComponent} from './page-history/page-history.component';
import {StructureComponent} from './structure/structure.component';
import {StructureEditComponent} from './structure-edit/structure-edit.component';
import {PageTreeComponent} from './pages/page-tree/page-tree.component';
import {DragulaModule, DragulaService} from 'ng2-dragula';
import {EditButtonComponent} from './edit-button/edit-button.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ElementsComponent} from './elements/elements.component';
import {FieldListComponent} from './field-list/field-list.component';
import {FieldListEditComponent} from './field-list-edit/field-list-edit.component';
import {FieldsComponent} from './fields/fields.component';
import {FieldsEditComponent} from './fields-edit/fields-edit.component';
import {LinkEditDialogComponent} from './link-edit/link-edit-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ElementPreviewComponent} from './elements/element-preview/element-preview.component';
import {ElementCreateComponent} from './elements/element-create/element-create.component';
import {ResizableModule} from 'angular-resizable-element';
import {FormComponent} from './form/form.component';
import {FormHistoryComponent} from './form/form-history/form-history.component';
import {FormHistoryDetailComponent} from './form/form-history-detail/form-history-detail.component';
import {TimeComponent} from './time/time.component';
import {SimpleTinyComponent} from './simple-tiny/simple-tiny.component';
import {TruncatePipe} from './pipes/truncate';
import {StripTagsPipe} from './pipes/stripTags';
import {SafeBgUrlPipe} from './pipes/safe-bg-url.pipe';
import {SearchPipe} from './pipes/search';
import {FileManagerComponent} from './file-manager/file-manager.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {PageService} from './services/page/page.service';
import {MessageService} from './services/message/message.service';
import {FileService} from './services/file/file.service';
import {CustobService} from './services/custob/custob.service';
import {CustobTypeService} from './services/custob-type/custob-type.service';
import {StructureService} from './services/structure/structure.service';
import {FormService} from './services/form/form.service';
import {FacebookComponent} from './facebook/facebook.component';
import {FacebookService} from './services/facebook.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {EditorModule} from '@tinymce/tinymce-angular';
import {SchoolService} from './services/school/school.service';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {CodaltInterceptor} from './codalt.interceptor';
import {ConfirmDialogService} from './services/confirm-dialog-service/confirm-dialog.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ConfirmDialogComponent} from './services/confirm-dialog-service/confirm-dialog/confirm-dialog.component';
import {SeoOptionsDialogComponent} from './page-edit/seo-options-dialog/seo-options-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {RequestRestorePasswordComponent} from './auth/request-restore-password/request-restore-password.component';
import {LoginComponent} from './auth/login/login.component';
import {RestorePasswordComponent} from './auth/restore-password/restore-password.component';
import {UsersOverviewComponent} from './users/users-overview/users-overview.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {AuthRolesComponent} from './auth/auth-roles/auth-roles.component';
import {UserFieldDialogComponent} from './auth/auth-roles/user-field-dialog/user-field-dialog.component';
import {LinkLoginComponent} from './auth/link-login/link-login.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SortingIconComponent} from './sorting-icon.component';
import {UserService} from './services/user/user.service';
import {AuthorisationService} from './services/auth/authorisation.service';
import {FileSelectionDialogComponent} from './file-manager/file-selection-dialog/file-selection-dialog.component';
import {FileAddItemComponent} from './file-manager/file-add-item-dialog/file-add-item.component';
import {UsersComponent} from './users/users.component';
import {UserDetailComponent} from './users/user-detail.component';
import {StudentDetailComponent} from './students/student-detail.component';
import {StudentsComponent} from './students/students.component';
import {ClassroomDetailComponent} from './classrooms/classroom-detail.component';
import {ClassroomsComponent} from './classrooms/classrooms.component';
import {ClassroomService} from './services/classroom/classroom.service';
import {StudentService} from './services/student/student.service';
import {UserAuthLogComponent} from './user-auth-log/user-auth-log.component';
import {SchoolSettingsComponent} from './school-settings/school-settings.component';
import '@angular/common/locales/global/nl';
import {AuthLogComponent} from './auth-log/auth-log.component';
import {InfoComponent} from './info/info.component';
import {ProfileComponent} from './profile/profile.component';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleEditComponent} from './articles/article-edit/article-edit.component';
import {ArticleService} from './services/article.service';
import {CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';
import {FieldErrorComponent} from './field-error/field-error.component';
import {EventsComponent} from './events/events.component';
import {EventEditComponent} from './events/event-edit/event-edit.component';
import {EventService} from './services/event.service';
import {EventDetailComponent} from './events/event-detail/event-detail.component';

import {ImageViewerDialogComponent} from './image-viewer-dialog/image-viewer-dialog.component';
import {SwiperModule} from 'swiper/angular';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {ArticleDetailComponent} from './articles/article-detail/article-detail.component';
import {CordovaService} from './services/cordova.service';
import {JobService} from './services/job.service';
import {JobsComponent} from './jobs/jobs.component';
import {JobEditComponent} from './jobs/job-edit/job-edit.component';
import {ChooseFileSourceComponent} from './file-manager/choose-file-source/choose-file-source.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {WebsocketService} from './services/websocket.service';
import {ChatService} from './services/chat.service';
import {NewChatDialogComponent} from './chat/new-chat-dialog/new-chat-dialog.component';
import {ChatMessagingComponent} from './chat/chat-messaging/chat-messaging.component';
import {ChatMessageService} from './services/chat-message.service';

import {SafeSrcPipe} from './pipes/safe-src.pipe';
import {CodaltImageComponent} from './codalt-image/codalt-image.component';
import {MatCardModule} from '@angular/material/card';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {CodaltComponent} from './codalt.component';
import {UiScrollModule} from 'ngx-ui-scroll';
import {UrlShareComponent} from './url-share/url-share.component';
import {DiscussionsComponent} from './discussions/discussions.component';
import {DiscussionService} from './services/discussion.service';
import {DiscussionEditComponent} from './discussions/discussion-edit/discussion-edit.component';
import {DiscussionDetailComponent} from './discussions/discussion-detail/discussion-detail.component';
import {ByIdPipe} from './pipes/by-id.pipe';
import {DiscussionPlanComponent} from './discussions/discussion-detail/discussion-plan/discussion-plan.component';
import {DiscussionSubscribeComponent} from './discussions/discussion-subscribe/discussion-subscribe.component';
import {NotByIdPipe} from './pipes/not-by-id.pipe';
import {IsBase64Pipe} from './pipes/is-base64.pipe';
import {ChatSettingsDialogComponent} from './chat/chat-settings-dialog/chat-settings-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ChatMessageInfoDialogComponent} from './chat/chat-message-info-dialog/chat-message-info-dialog.component';
import {WhereLessPipe} from './pipes/whereLess.pipe';
import {WhereGreaterOrEqualPipe} from './pipes/whereGreaterOrEqual.pipe';
import {MyPrivacyComponent} from './my-privacy/my-privacy.component';
import {SurveyEditComponent} from './survey-edit/survey-edit.component';
import {SurveyAnswerComponent} from './survey-answer/survey-answer.component';
import {SurveyService} from './services/survey.service';
import {SurveyAnswersComponent} from './survey-answers/survey-answers.component';
import {SelectedOptionsPipe} from './pipes/selected-options.pipe.ts';
import {TransactionOverviewComponent} from './profile/transaction-overview/transaction-overview.component';
import {PaymentService} from './services/payment.service';
import {AddCreditComponent} from './profile/transaction-overview/add-credit/add-credit.component';
import {PaymentCheckComponent} from './profile/transaction-overview/payment-check/payment-check.component';
import {PaymentsComponent} from './payments/payments.component';
import {PaymentEditComponent} from './payments/payment-edit/payment-edit.component';
import {DecimalInputDirective} from './directives/decimal-input.directive';
import {DefaultPaymentsComponent} from './payments/default-payments/default-payments.component';
import {PaymentDefaultService} from './services/payment-default.service';
import {DefaultPaymentEditComponent} from './payments/default-payments/default-payment-edit/default-payment-edit.component';
import {PayPaymentRequestComponent} from './payments/pay-payment-request/pay-payment-request.component';
import {AddCashCreditComponent} from './payments/add-cash-credit/add-cash-credit.component';
import {RemainPaymentsComponent} from './payments/remain-payments/remain-payments.component';

import {ByValuePipe} from './pipes/by-value.pipe';
import {PaymentsOverviewComponent} from './payments/payments-overview/payments-overview.component';
import {PaymentSchoolsComponent} from './payments/payments-overview/payment-schools/payment-schools.component';
import {PaymentDetailsComponent} from './payments/payments-overview/payment-details/payment-details.component';
import {WhereEmptyPipe} from './pipes/whereEmpty.pipe';
import {TextHrefPipe} from './pipes/textHref.pipe';
import {VideoComponent} from './video/video.component';
import {CustomErrorHandlerService} from './custom-error-handler-service';
import {LoggerService} from './services/logger.service';
import {WhereInPipe} from './pipes/where-in.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {ContextMenuModule} from '@perfectmemory/ngx-contextmenu';
import {ContainsTextPipe} from './pipes/contains-text.pipe';
import {ChatSearchPipe} from './chat/new-chat-dialog/chat-search.pipe';
import {ChatsSearchPipe} from './pipes/chat-search.pipe';
import {ReadByStudentsPipe} from './articles/article-detail/read-by-students.pipe';
import {IsJsonPipe} from './pipes/is-json.pipe';
import {ChatMessageComponent} from './chat/chat-message/chat-message.component';
import {PermissionQuestionsEditComponent} from './permission-questions-edit/permission-questions-edit.component';
import {PrivacyQuestionComponent} from './my-privacy/privacy-question/privacy-question.component';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {AbsenceRegisterComponent} from './absence-register/absence-register.component';
import {AbsencesComponent} from './absences/absences.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {CopyToArticleComponent} from './chat/chat-messaging/copy-to-article/copy-to-article.component';
import {RepliesComponent} from './replies/replies.component';
import {PermissionQuestionsComponent} from './permission-questions/permission-questions.component';
import {AbsenceCountsComponent} from './absence-counts/absence-counts.component';
import {PaymentRequestsComponent} from './payments/payment-requests/payment-requests.component';
import {DiscussionsOverviewComponent} from './discussions/payment-requests/discussions-overview.component';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {PinchZoomModule} from '@meddv/ngx-pinch-zoom';
import {VirtualScrollerModule} from '@iharbeck/ngx-virtual-scroller';
import {OrderByPipe} from './pipes/order-by.pipe';
import {FilterPermissionsPipePipe} from './pipes/filter-permissions-pipe.pipe';
import {PermissionStatsComponent} from './permission-questions/permission-stats/permission-stats.component';
import {PhoneDialogComponent} from './services/phone-service/phone-dialog/phone-dialog.component';
import {MatBadgeModule} from '@angular/material/badge';
import {EventSubscribeComponent} from './events/event-subscribe/event-subscribe.component';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {PayAfterSchoolOfferComponent} from './payments/pay-afterschool-offer/pay-after-school-offer.component';
import {EventPresenceComponent} from './events/event-presence/event-presence.component';
import {EventPartlyRefundComponent} from './events/event-partly-refund/event-partly-refund.component';
import {HasFeaturePipe} from './pipes/has-feature.pipe';

declare var Hammer: any;

export class MyHammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        let mc = new Hammer(element, {
            touchAction: 'pan-y'
        });
        return mc;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        CodaltComponent,
        HomeComponent,
        DetailComponent,
        ChatComponent,
        PagesComponent,
        PageEditComponent,
        PageHistoryComponent,
        StructureComponent,
        StructureEditComponent,
        PageTreeComponent,
        EditButtonComponent,
        ElementsComponent,
        FieldListComponent,
        FieldListEditComponent,
        FieldsComponent,
        FieldsEditComponent,
        LinkEditDialogComponent,
        ElementPreviewComponent,
        ElementCreateComponent,
        FormComponent,
        FormHistoryComponent,
        FormHistoryDetailComponent,
        TimeComponent,
        SimpleTinyComponent,
        TruncatePipe,
        StripTagsPipe,
        SafeBgUrlPipe,
        SafeSrcPipe,
        SearchPipe,
        ByIdPipe,
        NotByIdPipe,
        IsBase64Pipe,
        FilterPipe,
        WhereLessPipe,
        WhereGreaterOrEqualPipe,
        WhereEmptyPipe,
        ByValuePipe,
        TextHrefPipe,
        WhereInPipe,
        ReadByStudentsPipe,
        FileManagerComponent,
        FacebookComponent,
        ConfirmDialogComponent,
        SeoOptionsDialogComponent,
        LinkLoginComponent,
        UserFieldDialogComponent,
        AuthRolesComponent,
        UserEditComponent,
        UsersOverviewComponent,
        RestorePasswordComponent,
        RequestRestorePasswordComponent,
        LoginComponent,
        SortingIconComponent,
        UsersComponent,
        UserDetailComponent,
        StudentDetailComponent,
        StudentsComponent,
        ClassroomDetailComponent,
        ClassroomsComponent,
        UserAuthLogComponent,
        FileSelectionDialogComponent,
        FileAddItemComponent,
        AuthLogComponent,
        FileAddItemComponent,
        SchoolSettingsComponent,
        InfoComponent,
        ProfileComponent,
        ArticlesComponent,
        ArticleEditComponent,
        EventsComponent,
        EventEditComponent,
        FieldErrorComponent,
        EventDetailComponent,
        ImageViewerDialogComponent,
        ArticleDetailComponent,
        JobsComponent,
        JobEditComponent,
        ChooseFileSourceComponent,
        NewChatDialogComponent,
        ChatMessagingComponent,
        ChooseFileSourceComponent,
        CodaltImageComponent,
        UrlShareComponent,
        DiscussionsComponent,
        DiscussionEditComponent,
        DiscussionDetailComponent,
        DiscussionPlanComponent,
        DiscussionSubscribeComponent,
        ChatSettingsDialogComponent,
        ChatMessageInfoDialogComponent,
        MyPrivacyComponent,
        SurveyEditComponent,
        SurveyAnswerComponent,
        SurveyAnswersComponent,
        SelectedOptionsPipe,
        TransactionOverviewComponent,
        AddCreditComponent,
        PaymentCheckComponent,
        PaymentsComponent,
        PaymentEditComponent,
        DecimalInputDirective,
        DefaultPaymentsComponent,
        DefaultPaymentEditComponent,
        PayPaymentRequestComponent,
        AddCashCreditComponent,
        RemainPaymentsComponent,
        PaymentsOverviewComponent,
        PaymentSchoolsComponent,
        PaymentDetailsComponent,
        VideoComponent,
        ChatSearchPipe,
        ContainsTextPipe,
        ChatsSearchPipe,
        IsJsonPipe,
        Nl2brPipe,
        ChatMessageComponent,
        PermissionQuestionsEditComponent,
        PrivacyQuestionComponent,
        AbsenceRegisterComponent,
        AbsencesComponent,
        SafeHtmlPipe,
        CopyToArticleComponent,
        RepliesComponent,
        PermissionQuestionsComponent,
        AbsenceCountsComponent,
        PaymentRequestsComponent,
        DiscussionsOverviewComponent,
        SafeUrlPipe,
        OrderByPipe,
        FilterPermissionsPipePipe,
        PermissionStatsComponent,
        PhoneDialogComponent,
        EventSubscribeComponent,
        PayAfterSchoolOfferComponent,
        EventPresenceComponent,
        EventPartlyRefundComponent,
        HasFeaturePipe
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatDialogModule,
        MatButtonToggleModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatDialogModule,
        DragulaModule.forRoot(),
        ModalModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ResizableModule,
        EditorModule,
        MatExpansionModule,
        NgSelectModule,
        MatProgressBarModule,
        HammerModule,
        ScrollingModule,
        MatNativeDateModule,
        VirtualScrollerModule,
        SwiperModule,
        MatCardModule,
        MatRippleModule,
        MatMenuModule,
        AngularSvgIconModule.forRoot(),
        ContextMenuModule,
        UiScrollModule,
        DragDropModule,
        PinchZoomModule,
        MatBadgeModule,
        MatAutocompleteTrigger,
        MatAutocomplete
    ],
    providers: [
        ApiService,
        WebsocketService,
        AuthenticationService,
        PageService,
        MessageService,
        FileService,
        CustobService,
        CustobTypeService,
        StructureService,
        FormService,
        DragulaService,
        FacebookService,
        SchoolService,
        ConfirmDialogService,
        UserService,
        AuthenticationService,
        AuthorisationService,
        ClassroomService,
        StudentService,
        ArticleService,
        EventService,
        SurveyService,
        JobService,
        CdkVirtualScrollViewport,
        FirebaseX,
        MatBottomSheet,
        CordovaService,
        ChatService,
        ChatMessageService,
        DiscussionService,
        PaymentService,
        PaymentDefaultService,
        LoggerService,
        IsBase64Pipe,
        TextHrefPipe,
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
        {provide: LOCALE_ID, useValue: 'nl'},
        {provide: HTTP_INTERCEPTORS, useClass: CodaltInterceptor, multi: true},
        {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}
    ],
    bootstrap: [AppComponent],
    exports: [
        ScrollingModule
    ]
})
export class AppModule {
}
